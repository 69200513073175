<template>
    <div id="secure">
        <h1>Auftrag vergeben</h1>
        ({{ paw.description }})
        <br>&nbsp;<br>&nbsp;

        <md-table v-model="searchedPartners" md-sort="companyName" md-sort-order="asc" md-card md-fixed-header>
            <md-table-toolbar>
                <md-field md-clearable className="md-toolbar-section-end">
                    <md-input placeholder="Suche in Partnerdaten..." v-model="searchPartners" @input="searchOnPartnersTable"/>
                </md-field>
            </md-table-toolbar>

            <md-table-empty-state
                md-label="Nichts gefunden..."
                :md-description="`Keine Partner zur Eingabe '${searchPartners.length > 0 ? searchPartners : '-'}' gefunden.`">
            </md-table-empty-state>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Firmenname" md-sort-by="companyName">{{ item.companyName }}</md-table-cell>
                <md-table-cell md-label="Adresse">{{ item.street }}, {{ item.postCode }} {{ item.city }}</md-table-cell>
                <md-table-cell md-label="Inhaber/-in">{{ item.firstName }} {{ item.lastName }}</md-table-cell>
                <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
                <md-table-cell md-label="Aktionen">
                    <md-button @click="offer(item)" v-show="true" class="md-primary md-raised">Beauftragen!</md-button>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <br>
        <br>
        <md-button class="md-raised md-accent" to="/user/dashboard/">Abbrechen</md-button>
    </div>
</template>

<script>
const toLower = text => {
    return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
    if (term) {
        return items.filter(item =>
            toLower(item.companyName).includes(toLower(term))
            || toLower(item.postCode).includes(toLower(term))
            || toLower(item.street).includes(toLower(term))
            || toLower(item.city).includes(toLower(term))
            || toLower(item.firstName).includes(toLower(term))
            || toLower(item.lastName).includes(toLower(term))
            || toLower(item.email).includes(toLower(term))
        )
    }

    return items
}

export default {
    name: 'User_PAW_offer',
    components: {},
    props: {},
    data: () => ({
        // Partners
        searchedPartners: [],
        searchPartners: '',
        currentSort: 'companyName',
        currentSortOrder: 'asc',
    }),
    mounted() {
        this.$store.dispatch("loadAllPartners")
        const t = this
        const j = setInterval(function () {
            if (t.$store.state.partners && t.$store.state.partners.length > 0) {
                t.searchOnPartnersTable()
                clearInterval(j)
            }
        }, 10);
    },
    computed: {
        sending: function () {
            return this.$store.state.rxtx > 0
        },
        paw: function () {
            for (let elem of this.$store.state.paws) {
                if (elem.pawId === this.$route.params.pawId) {
                    return elem
                }
            }
            return undefined
        },
        partners: function () {
            const p = this.$store.state.partners
            return p ? p : []
        },
    },
    methods: {
        searchOnPartnersTable() {
            this.searchedPartners = searchByName(this.partners, this.searchPartners)
        },
        offer(item) {
            let offerReq = {
                pawId: this.$route.params.pawId,
                companyName: "DE-" + item.postCode + "-" + item.companyName
            }
            this.$store.dispatch("offerPaw", offerReq).then(() => {
                this.$store.dispatch("showSnackbar", {text: "Auftrag erfolgreich erteilt."})
                this.$router.push("/user/paw/detail/" + this.$route.params.pawId)
            }, this).catch(() => {
                this.$store.dispatch("showSnackbar", {text: "Beauftragung fehlgeschlagen. Ist der Auftrag vielleicht schon vergeben?"})
            })
        }
    }
}
</script>

<style scoped>
#secure {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}

table {
    display: inline-block;
    border-collapse: collapse;
}

table > tr > th {
    font-size: large;
    padding: 10px;
    white-space: nowrap;
}

table > tr > td {
    padding: 10px;
}

table > tr:nth-child(odd) {
    background: #EEE;
}

table > tr:nth-child(even) {
    background: #FFF;
}
</style>


<!-- TODO Werte validieren -->
